//
// Topbar
//




$kt-topbar-icon-border-color:  rgba(#fff, 0.2);
$kt-topbar-icon-border-hover-color:  rgba(#fff, 0.5);
$kt-topbar-icon-color: rgba(#fff, 0.85);

$kt-topbar-minimize-icon-border-color: #e7e8f4;
$kt-topbar-minimize-icon-border-hover-color: #d7d8e9;

$kt-topbar-icon-size: 42px;
$kt-topbar-minimize-icon-size: 34px;
$kt-topbar-mobile-icon-size: 34px;

// General mode
.kt-header__topbar {
	display: flex;
	align-items: stretch;
	padding: 0;
	align-content: flex-end;

	// Item
	.kt-header__topbar-item {
		display: flex;
		align-items: stretch;
		margin: 0 0.25rem;

		// Wrapper
		.kt-header__topbar-wrapper {
			cursor: pointer;
			display: flex;
			align-items: stretch;

			.kt-badge {
				position: absolute;
				left: 50%;
				width: 4px;
				height: 4px;
				background-clip: padding-box; /* Firefox 4+, Opera, for IE9+, Chrome */
				margin-left: -1.5px;
				top: 15px;
			}

			.kt-header__topbar-icon {
				display: flex;
				align-items: center;
				align-self: center;
				justify-content: center;
				height: $kt-topbar-icon-size;
				width: $kt-topbar-icon-size;
				cursor: pointer;
				background: transparent; 
				@include kt-transition();

				@include kt-rounded {
					border-radius: $kt-border-radius;
				}

				i {
					line-height: 0;
					font-size: 1.3rem;
					color: $kt-topbar-icon-color;
				}	

				svg {
					height: 24px;
					width: 24px;
				}

				@include kt-svg-icon-color(#ffffff);
			}		
		}		

		// Hover & show state
		&:hover,
		&.show {
			.kt-header__topbar-icon {
				@include kt-transition();
				background-color: rgba(#fff, 0.1);
				
				i {
					color: #fff !important;
				}	
			}
		}

		// Languages
		&.kt-header__topbar-item--langs {
			margin-right: 0.75rem;

			.kt-header__topbar-icon {
				img {
					width: 22px;

					@include kt-rounded {
						border-radius: 2px;
						//border-radius: $kt-border-radius;
					}
				}
			}

			.dropdown-menu {
				.kt-nav__link-icon {
					padding-right: 10px;

					img {
						width: 18px;

						@include kt-rounded {
							border-radius: 2px;
						}
					}
				}
			}
		}		

		// User profile
		&.kt-header__topbar-item--user {
			// Welcome
			.kt-header__topbar-welcome {
				display: flex;
				align-self: center;
				padding: 0 0.55rem 0 0;
				font-weight: 500;
				font-size: 0.9rem;
				color: rgba(#fff, 0.5);
			}

			// Username
			.kt-header__topbar-username {
				display: flex;
				align-self: center;
				padding: 0 0.55rem 0 0;
				font-weight: 500;
				font-size: 1rem;
				color: rgba(#fff, 0.85);
			}

			// Wrapper
			.kt-header__topbar-wrapper {
				// User pic placeholder
				.kt-header__topbar-icon {
					background-color: rgba(#fff, 0.1);

					b {
						font-size: 1.4rem;
						font-weight: 500;
						color: #fff;
					}
				}

				// User pic
				img {
					align-self: center;
					height: $kt-topbar-icon-size;
					
					@include kt-rounded {
						border-radius: $kt-border-radius;
					}
				}
			}		

			&:hover,
			&.show {
				// Wrapper
				.kt-header__topbar-wrapper {
					// User pic placeholder
					.kt-header__topbar-icon {
						background-color: rgba(#fff, 0.2);
					}
				}
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}

	// Fixed header minimize
	.kt-header--fixed.kt-header--minimize & {
		// Item
		.kt-header__topbar-item {
			// Icon
			.kt-header__topbar-icon {
				i {
					color: #9ea4bc;
				}

				@include kt-svg-icon-color(#9ea4bc);
			}

			// Hover & show state
			&:hover,
			&.show {
				.kt-header__topbar-icon {
					background-color: #f8f8fb;
					
					i {
						color: #959cb6 !important;
					}	
				}
			}

			// User profile
			&.kt-header__topbar-item--user {
				// Welcome
				.kt-header__topbar-welcome {
					color: lighten(#959cb6, 5%);
				}

				// Username
				.kt-header__topbar-username {
					color: #959cb6;
				}

				// Wrapper
				.kt-header__topbar-wrapper {
					// User pic placeholder
					.kt-header__topbar-icon {
						background-color: #f8f8fb;

						b {
							color: #959cb6;
						}
					}
				}		

				&:hover,
				&.show {
					// Wrapper
					.kt-header__topbar-wrapper {
						// User pic placeholder
						.kt-header__topbar-icon {
							background-color: darken(#f8f8fb, 3%);
						}
					}
				}
			}
		}
	}
}

// Tablet and mobile mode(1024px and below)
@include kt-tablet-and-mobile() {
	.kt-header__topbar {
		padding: 0 kt-get($kt-page-padding, mobile);
		background-color: #fff;
		transition: all 0.3s ease;
		margin-top: -(kt-get($kt-header-config, topbar, height, mobile));
		height: kt-get($kt-header-config, topbar, height, mobile);
		position: absolute;
		left: 0;
		right: 0;
		justify-content: flex-end;
		width: 100%;
		border-top: 1px solid transparent;

		// Fixed mobile header
		.kt-header-mobile--fixed & {
			position: fixed;
			z-index: kt-get($kt-header-config, base, mobile, self, fixed, zindex) - 1;
		}

		// Topbar shown
		.kt-header__topbar--mobile-on & {
			margin-top: 0;
			transition: all 0.3s ease;
			box-shadow: kt-get($kt-header-config, base, mobile, self, fixed, box-shadow);
			border-top: 1px solid kt-base-color(grey, 2);
		}

		// Topbar item
		.kt-header__topbar-item {
			margin: 0 0.25rem;
			
			.kt-header__topbar-wrapper {
				display: flex;
				align-items: stretch;

				.kt-badge {
					top: 6px;		
					width: 4px;
					height: 4px;
					margin-left: -2px;
				}	

				.kt-header__topbar-icon {
					max-height: $kt-topbar-mobile-icon-size;
					max-width: $kt-topbar-mobile-icon-size;
					border: 0;

					i {
						font-size: 1.3rem;		
						color: kt-base-color(label, 2);
					}		

					@include kt-svg-icon-color(kt-base-color(label, 2));
				}
			}

			&:hover,
			&.show {
				.kt-header__topbar-icon {
					@include kt-transition();
					background-color: kt-brand-color();
					
					i {
						color: kt-brand-color(inverse);
					}	

					@include kt-svg-icon-color(#ffffff);
				}				
			}

			// Languages
			&.kt-header__topbar-item--langs {
				margin: 0 1.2rem 0 0.7rem;				
			}						

			&.kt-header__topbar-item--user {
				padding: 0 0 0 0.25rem;
				margin: 0;

				.kt-header__topbar-welcome {
					color: kt-base-color(label, 2);
					padding: 0 0.35rem 0 0;
					font-size: 0.9rem;
				}

				.kt-header__topbar-username {
					color: kt-base-color(label, 3);
					padding: 0 0.55rem 0 0;
					font-size: 0.9rem;
				}

				.kt-header__topbar-wrapper {
					.kt-header__topbar-icon {
						background-color: rgba(kt-base-color(label, 2), 0.1);

						b {
							font-size: 1.4rem;
							font-weight: 500;
							color: kt-base-color(label, 2);
						}
					}

					img {
						max-height: $kt-topbar-mobile-icon-size;
						margin: 0 0.5rem 0 0.2rem;
					}
				}

				&:hover,
				&.show {
					// Wrapper
					.kt-header__topbar-wrapper {
						// User pic placeholder
						.kt-header__topbar-icon {
							background-color: kt-brand-color();

							b {
								color: kt-brand-color(inverse);
							}
						}
					}
				}
			}	

			// Search
			&.kt-header__topbar-item--search {
				.kt-input-icon {
					.kt-input-icon__icon {
						i {
							color: kt-base-color(label, 2);
						}
					}

					.form-control {
						height: $kt-topbar-mobile-icon-size;
						border: 1px solid $kt-topbar-minimize-icon-border-color;
						color: kt-base-color(label, 3);
						border: 0;

						@include kt-input-placeholder(kt-base-color(label, 2));

						&:focus {
							border: 0;		
						}
					}
				}
			}

			&:last-child {
				padding-right: 0 !important;
				margin-right: 0 !important;
			}
		}	
	}
}